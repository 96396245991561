import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './interceptors/token/token-interceptor.service';
import { DomainInterceptor } from './interceptors/domain/domain.interceptor';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  exports: [],
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DomainInterceptor,
      multi: true,
    }
  ],
})
export class CoreModule { }
