import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { AccountService } from '@core/services/account/account.service';
import { environment } from '@env';
import { CoreModule } from '@core/core.module';
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, CoreModule, HttpClientModule, TranslocoRootModule],
  providers: [
    {
      provide: 'API_URL',
      useValue: environment.apiUrl,
    },
    {
      provide: 'SET_LINK_URL',
      useValue: environment.setLinkUrl,
    },
    AccountService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
