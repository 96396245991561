import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DomainInterceptor implements HttpInterceptor {

  constructor(@Inject('API_URL') private apiUrl) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url;
    if (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('/assets')) {
      return next.handle(request);
    }
    return next.handle(request.clone({url: this.apiUrl + url}));
  }
}
