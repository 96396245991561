import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfile } from '@model/user-profile';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token/token.service';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { Response } from '@model/response';
import { Role } from '@model/role';
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private cacheProfile$: Observable<UserProfile>;
  private roleKey = 'roles';

  constructor(private http: HttpClient, private ts: TokenService) {}

  public getUser(): Observable<UserProfile> {
    if (!this.cacheProfile$) {
      const url = '/profile';
      this.cacheProfile$ = this.http.get<Response<UserProfile>>(url, this.config).pipe(
        map((d) => d.data),
        publishReplay(1),
        refCount()
      );
    }
    return this.cacheProfile$;
  }

  get config(): { [s: string]: any } {
    return {
      'Content-Type': 'application/json',
      headers: { Authorization: `Bearer ${this.ts.token}` },
    };
  }

  get roles(): Role[] {
    const roles = sessionStorage.getItem(this.roleKey);
    return roles ? JSON.parse(roles) : [];
  }

  set roles(roles: Role[]) {
    sessionStorage.setItem(this.roleKey, roles ? JSON.stringify(roles) : null);
  }
}
