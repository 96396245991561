import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@model/response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface RefreshTokenResponse {
  token; string;
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private tokenKey = 'token';
  private refreshKey = 'refresh-token';

  constructor(private http: HttpClient) {}

  public refreshToken(): Observable<RefreshTokenResponse> {
    const url = '/auth/refreshToken';
    const config = {
      'Content-Type': 'application/json',
      headers: { Authorization: `Bearer ${this.refresh}` },
    };
    return this.http.post<Response<RefreshTokenResponse>>(url, null, config).pipe(map((d) => d.data));
  }

  get token(): string {
    const token = sessionStorage.getItem(this.tokenKey);
    return typeof token === 'string' && token.length > 0 ? token : null;
  }

  set token(token: string) {
    sessionStorage.setItem(this.tokenKey, token);
  }

  clear(): void {
    sessionStorage.removeItem(this.tokenKey);
    sessionStorage.removeItem(this.refreshKey);
  }

  get refresh(): string {
    const token = sessionStorage.getItem(this.refreshKey);
    return typeof token === 'string' && token.length > 0 ? token : null;
  }

  set refresh(token: string) {
    sessionStorage.setItem(this.refreshKey, token);
  }
}
