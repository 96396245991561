import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { RefreshTokenResponse, TokenService } from '@core/services/token/token.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private token: TokenService, @Inject('SET_LINK_URL') private setLinkUrl: string) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.token.token;
    if (token && !request.headers.has('Authorization')) {
      request = this.addToken(request, token);
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.url.includes('refreshToken')) {
      this.token.clear();
      window.location.href = this.setLinkUrl;
      return next.handle(request);
    }
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.token.refreshToken().pipe(
        catchError((error) => {
          window.location.href = this.setLinkUrl;
          return throwError(error);
        }),
        switchMap(({ token }: RefreshTokenResponse) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token);
          this.token.token = token;
          return next.handle(this.addToken(request, token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
